




































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import validator from "validator";

interface StepError {
  step: number;
  error: string;
}

const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(new Blob([file]));
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },

  setup(_, { root, emit }) {
    const search = ref<HTMLInputElement | null>(null);

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      partnerPhoto: "",
      oldImage: null as null | { id: string },
    });

    const model = reactive<{
      data: any;
    }>({
      data: {},
    });

    //  Start fetch single partner data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`partner/${root.$route.params.id}`)
        .then(({ data: { partner } }) => {
          model.data.name = partner.name || undefined;
          model.data.website = partner.website || undefined;
          model.data.description = partner.description || null;

          state.oldImage = partner.image || undefined;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single partner data

    watch(
      () => model,
      (newModel) => emit("input", newModel),
      { deep: true }
    );

    const rules = {
      postalCode: [
        (v: string) =>
          !v ||
          validator.isPostalCode(v, "PL") ||
          "Podaj poprawny kod pocztowy",
      ],
      website: [
        (v: string) =>
          !v ||
          validator.isURL(v, {
            protocols: ["http", "https"],
            require_protocol: true,
          }) ||
          root.$tc("layout.misc.validWebsiteAddress"),
      ],
    };

    watch(
      () => model.data.image,
      async (newPhoto) => {
        state.partnerPhoto = (await toBase64(newPhoto)) as string;
      }
    );

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      let imageId = state.oldImage?.id;

      const formData = new FormData();

      formData.append("image", model.data.image);

      if (model.data.image) {
        await axiosInstance
          .post("image/partner", formData)
          .then(({ data: { id } }) => {
            imageId = id;
          })
          .catch((e) => console.log(e));
      }

      const data = {
        name: model.data.name,
        image: imageId || undefined,
        website: model.data.website || undefined,
        description: model.data.description || null,
      };

      state.loading = true;

      axiosInstance
        .put(`partner/${root.$route.params.id}`, data)
        .then(() => {
          state.success = true;
          model.data = {};
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Partner już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/partner/`
    );

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      fetchData,
      search,
      rules,
      filesUrl,
    };
  },
});
